import React from 'react';
import { Link } from 'react-router-dom';
import { HOMEPAGE_ROUTE, DASHBOARD_ROUTE } from '../_pages';
import { withFirebase } from '../_contexts';

export function NavbarComponent({ toggleSidebar, firebase }) {
    return (
        <div className="Navbar">

            <a href="https://skylark.net.nz" className="text-2xl py-2 px-4 font-bold title">
              <img src="./logo.jpg" alt="Skylark Logo" className="blog-logo" />
            </a>

            <div>
                {
                    firebase.auth.currentUser ?
                    <>
                      <Link className="uppercase focus:outline-none mx-2"
                            to={HOMEPAGE_ROUTE}>Blogs</Link>
                        <Link className="uppercase focus:outline-none mx-2"
                            to={DASHBOARD_ROUTE}>Dashboard</Link>
                        <button className="uppercase focus:outline-none mx-2"
                            onClick={() => {
                                firebase.auth.signOut();
                            }}>Logout</button>
                    </> :
                    <>
                      <Link className="uppercase focus:outline-none mx-2"
                            to={HOMEPAGE_ROUTE}>Blogs</Link>
                    </>
                }

            </div>
        </div>
    )
}

export const Navbar = withFirebase(NavbarComponent);